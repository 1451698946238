import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { MarkdownModule } from 'ngx-markdown';
import { ModalService } from 'ngx-modal-ease';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserMessageDto } from '../../api/aria/models';
import { AppTheme } from '../../constants/themeConstans';
import { RoleType } from '../../models/role-type';
import { ThemeService } from '../../services/theme.service';
import { ThinkService } from '../../services/think.service';
import { StringUtils } from '../../utils/string.utils';
import { HistoryComponent } from '../history/history.component';
import { TeInitComponent } from '../te-init/te-init.component';

@Component({
  selector: 'aria-ai-message',
  standalone: true,
  imports: [CommonModule, LottieComponent, FormsModule, MarkdownModule, SvgIconComponent],
  templateUrl: './ai-message.component.html',
  styleUrl: './ai-message.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AiMessageComponent extends TeInitComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isAdmin!: boolean;
  @Input() isGenerating!: boolean;
  @Input() isLastElement!: boolean;
  @Input() message!: UserMessageDto;

  @Output() regenerateMessageEvent = new EventEmitter<string>();

  errorText = 'Something went wrong. Please try again, and if the issue persists, contact our support team.';
  roleType = RoleType;

  private _showControls!: boolean;

  convertedMessage: string = '';
  isAriaThinkingObs$!: Observable<boolean>;

  ariaThinkWhite = '/assets/lottie/ARI_WRITING_WHITE.json';
  ariaThinkDark = '/assets/lottie/ARI_WRITING_DARK.json';

  lottieOptions!: AnimationOptions;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _thinkService: ThinkService,
    private _themeService: ThemeService,
    private _modalService: ModalService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this._unsubscribe$ = new Subject();
    this.isAriaThinkingObs$ = this._thinkService.ariaThinkingObs;

    this._themeService._themeObs.pipe(takeUntil(this._unsubscribe$)).subscribe({
      next: (theme: string) => {
        if (theme === AppTheme.White) {
          this.lottieOptions = {
            path: this.ariaThinkWhite,
          };
          return;
        }

        this.lottieOptions = {
          path: this.ariaThinkDark,
        };
      },
    });
  }

  ngOnChanges(): void {
    if (!StringUtils.isNullOrEmpty(this.message?.errorCode ?? '')) {
      switch (this.message.errorCode) {
        case 'DailyLimit':
          this.errorText = 'Message limit reached, please try again tomorrow!';
          break;
        default:
          this.errorText =
            'Something went wrong. Please try again, and if the issue persists, contact our support team.';
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.complete();
  }

  showPromptConfigurations() {
    this._modalService.open(HistoryComponent, {
      modal: {
        enter: 'enter-scale-down 0.1s ease-out',
      },
      overlay: {
        leave: 'fade-out 0.3s',
      },
      size: {
        width: '600px',
        height: '600px',
        padding: '10px',
      },
      data: {
        messageId: this.message?.groupId,
      },
    });
  }

  regenerateMessage() {
    this.regenerateMessageEvent.emit();
  }

  copyAnswerToClipBoard() {
    if (!this.message?.text) return;
    navigator.clipboard.writeText(this.message.text);
  }

  set showControls(show: boolean) {
    if (this.isLastElement) return;
    this._showControls = show;
  }

  get showControls() {
    return this._showControls;
  }
}
