import { Component, OnInit } from '@angular/core';
import { Ripple, initTWE } from 'tw-elements';

@Component({
  selector: 'aria-te-init',
  standalone: true,
  imports: [],
  templateUrl: './te-init.component.html',
  styleUrl: './te-init.component.scss',
})
export class TeInitComponent implements OnInit {
  ngOnInit(): void {
    initTWE({ Ripple });
  }
}
