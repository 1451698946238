import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AppRoutes } from '../../constants/appRoutes';
import { Providers } from '../../constants/authConstants';
import { AuthService } from '../../services/auth.service';
import { TeInitComponent } from '../te-init/te-init.component';

@Component({
  selector: 'aria-provider',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './provider.component.html',
  styleUrl: './provider.component.scss',
})
export class ProviderComponent extends TeInitComponent implements OnInit {
  @Input() useGrid: boolean = true;
  @Input() skip!: Array<Providers>;

  providers = Object.keys(Providers).map((x) => x);

  constructor(private _authService: AuthService, private _router: Router) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.skip && this.skip.length > 0) {
      this.providers = this.providers.filter((x) => !this.skip.includes(x as Providers));
    }
  }

  loginProvider(provider: string) {
    if (provider === Providers.Institution) {
      this._router.navigate([AppRoutes.InstitutionLogin]);
      return;
    }

    this._authService.loginSocials(provider);
  }
}
