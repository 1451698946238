import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, concatMap, of, tap, map } from 'rxjs';
import { UserMessageDto, UserSessionDto, NkodaLinksDto } from '../api/aria/models';
import { LinkService, MessageService } from '../api/aria/services';
import { RoleType } from '../models/role-type';
import { StringUtils } from '../utils/string.utils';
import { SessionService } from './../api/aria/services/session.service';
import { SessionModel } from './../models/session-model';
import { NkodaLinkPanelService } from './nkoda-link-panel.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class OpenAiService {
  private _sessionModelSubject = new BehaviorSubject<Array<SessionModel>>([]);
  public sessionModelObs = this._sessionModelSubject.asObservable();

  private _sessionModels = new Array<SessionModel>();
  aiMessage!: UserMessageDto;

  constructor(
    private _sessionService: SessionService,
    private _messageService: MessageService,
    private _settingsService: SettingsService,
    private _nkodaLinkPanelService: NkodaLinkPanelService,
    private _linkService: LinkService,
  ) {}

  selectSession(item: SessionModel) {
    if (this._sessionModels.find((x) => x.isSelected)?.isSelected) {
      this._sessionModels.find((x) => x.isSelected)!.isSelected = false;
    }

    this._sessionModels.find((x) => x.id === item.id)!.isSelected = true;

    return this.getUserSessionMessages().pipe(
      tap((messages: Array<UserMessageDto> | null) => {
        if (messages === null) {
          this._sessionModelSubject.next(this._sessionModels);
          return;
        }

        this._sessionModels.find((x) => x.isSelected)!.messages = messages;
        this._sessionModelSubject.next(this._sessionModels);
      }),
    );
  }

  deleteSession(item: SessionModel) {
    const index = this._sessionModels.findIndex((x) => x.id === item.id);
    const isCurrentSelected = this._sessionModels[index].isSelected;
    this._sessionModels.splice(index, 1);

    if (isCurrentSelected && this._sessionModels.length > 0) {
      this._sessionModels[0].isSelected = true;
    }

    if (this._sessionModels.length === 0) {
      this._sessionModels = [];
    }

    this._sessionModelSubject.next(this._sessionModels);
    this._nkodaLinkPanelService.data = {};

    return this._sessionService.apiSessionSessionIdDelete({
      sessionId: item.id as string,
    });
  }

  setMessage(message: UserMessageDto) {
    if (this._sessionModels.length === 0) {
      this._sessionModels.push({
        isSelected: true,
      } as SessionModel);
    }

    const selectedSession = this._sessionModels.find((x) => x.isSelected);
    if (!selectedSession) return;

    if (!selectedSession.messages) selectedSession.messages = [];
    selectedSession.messages.push(message);

    if (!selectedSession.firstMessage) {
      selectedSession.firstMessage = message;
    }

    selectedSession.updatedAt = new Date().toUTCString();

    this._sessionModels = this._sessionModels.filter((x) => !x.isSelected);
    this._sessionModels.unshift(selectedSession);
    this._sessionModelSubject.next(this._sessionModels);
  }

  sendMessage(message: string): Observable<UserMessageDto> {
    const selectedSession = this._sessionModels.find((x) => x.isSelected);
    if (selectedSession?.id) {
      return this.postMessage(message, selectedSession.id);
    }

    return this.createSession().pipe(
      concatMap((sessionResponse: UserSessionDto) => {
        return this.postMessage(message, sessionResponse.id as string);
      }),
    );
  }

  postMessage(message: string, sessionId: string) {
    this.setMessageInCurrentThreadLocally(message);
    this.aiMessage = {
      id: StringUtils.uuidv4(),
      text: null,
      role: RoleType.Aria,
      sessionId,
    } as UserMessageDto;

    this.setMessage(this.aiMessage);

    return this._messageService
      .apiMessagePost$Json({
        body: {
          id: this.aiMessage.id,
          sessionId,
          text: message,
          role: RoleType.User,
          configId: this._settingsService.userSettings.modelConfigId,
        },
      })
      .pipe(
        tap((message: UserMessageDto) => {
          const selectedSession = this._sessionModels.find((x) => x.isSelected);
          if (!selectedSession) return;
          this._sessionModels.find((x) => x.isSelected)!.messages.pop();
          this._sessionModels.find((x) => x.isSelected)!.messages.push(message);

          this._sessionModelSubject.next(this._sessionModels);
        }),
        concatMap((messageResponse: UserMessageDto) => {
          const selectedSession = this._sessionModels.find((x) => x.isSelected);
          const lastMessageText = selectedSession?.messages[selectedSession.messages.length - 1]?.text || '';

          return this._linkService
            .apiLinkPost$Json({
              body: {
                id: this.aiMessage.id,
                sessionId,
                text: lastMessageText,
                role: RoleType.User,
                configId: this._settingsService.userSettings.modelConfigId,
              },
            })
            .pipe(
              tap((rez: NkodaLinksDto) => {
                this._nkodaLinkPanelService.data = rez;
              }),
              map(() => messageResponse),
            );
        }),
      );
  }

  createSession() {
    if (this._sessionModels.find((x) => x.isSelected)?.isSelected) {
      this._sessionModels.find((x) => x.isSelected)!.isSelected = false;
    }

    const createThreadObs$ = this._sessionService.apiSessionPost$Json().pipe(
      tap((session: UserSessionDto) => {
        if (session) {
          const sessionModel = session as SessionModel;
          sessionModel.isSelected = true;
          this._sessionModels.unshift(sessionModel);
          this._sessionModelSubject.next(this._sessionModels);
        }
      }),
    );

    return createThreadObs$;
  }

  getUserData(): Observable<Array<UserMessageDto> | null> {
    const getUserSessionObs$ = this.getUserSession();
    return getUserSessionObs$.pipe(concatMap(() => this.getUserSessionMessages()));
  }

  getUserSession(): Observable<Array<UserSessionDto>> {
    return this._sessionService.apiSessionGet$Json().pipe(
      tap((userSessions: Array<UserSessionDto>) => {
        const userSessionModels = userSessions.map((userSession: UserSessionDto) => {
          const userSessionModel = userSession as SessionModel;
          return userSessionModel;
        });

        this._sessionModels = userSessionModels;
        this._sessionModelSubject.next(this._sessionModels);
      }),
    );
  }

  getUserSessionMessages(): Observable<Array<UserMessageDto> | null> {
    if (!this._sessionModels.length) return of(null);

    const selectedSession = this._sessionModels.find((x) => x.isSelected);
    if (!selectedSession) return of(null);

    return this._sessionService
      .apiSessionSessionIdMessagesGet$Json({
        sessionId: selectedSession.id!,
      })
      .pipe(
        tap((sessionMessages: Array<UserMessageDto>) => {
          this.getMessageNkodaRelatedContent(selectedSession.id!);

          if (!this._sessionModels.find((x) => x.isSelected)) return;
          this._sessionModels.find((x) => x.isSelected)!.messages = sessionMessages;
        }),
      );
  }

  getMessageNkodaRelatedContent(sessionId: string) {
    this._linkService
      .apiLinkSessionIdGet$Json({
        sessionId,
      })
      .subscribe({
        next: (data) => {
          this._nkodaLinkPanelService.data = data;
        },
      });
  }

  public setMessageInCurrentThreadLocally(message: string) {
    this.setMessage({
      text: message,
      role: RoleType.User,
    });

    this._sessionModelSubject.next(this._sessionModels);
  }
}
